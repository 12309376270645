<style scoped>
  @import "../../assets/css/mobile-tablet/homePreviewImagesGrid.scss";
</style>
<template>
  <div class="homePreviewImagesGridMobileTablet__container">
    <main class="homePreviewImagesGridMobileTablet--main">
      <div class="homePreviewImagesGridMobileTablet--content">
        <div class="homePreviewImagesGridMobileTablet--grid">
          <div  v-for="projectPreview in projectPreviewsMobileTablet"
                :key="projectPreview.projectId"
                :class="`homePreviewImagesGridMobileTablet--grid__item
                         homePreviewImagesGridMobileTablet--pos-${projectPreview.projectId}`">
            <div
              :class="`homePreviewImagesGridMobileTablet--grid__item-img`"
              :style="{'background-image': 'url(' + require('@/assets/img/projects/' + projectPreview.slug + '/' + projectPreview.imagesPreview) + ')'}"

            ></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import sourceData from "@/data/home_preview_images_grid_mobile_tablet.json";
// import gsap from 'gsap';
// import $ from 'jquery'

export default {
  name: "homePreviewImagesGridMobileTablet",
  components: {},
  methods: {
    getImgUrlImageProjects: function (projectName, imagePath) {
      return require("@/assets/img/projects/" + projectName + "/" + imagePath);
    },
  },
  computed: {
    projectPreviewsMobileTablet() {
      // console.log(sourceData.projectPreviewsMobileTablet);
      return sourceData.projectPreviewsMobileTablet;
    },
  },
  mounted: function () {
  },
};
</script>
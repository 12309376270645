import imagesLoaded from 'imagesloaded'
//const charming = require('charming')
//import anime from 'animejs/lib/anime.es.js';

var scrollingAnimation5 = {
    animation(){
        class Entry {
            constructor(el) {
                this.DOM = {el: el};
                this.DOM.image = this.DOM.el.querySelector('.home__content__img');
                this.DOM.title = this.DOM.el.querySelector('.home__content__text');
                observer.observe(this.DOM.el);
            }
            enter(direction) {
                if(direction){
                    const bodyEl = document.body;
                    // We show the image only if it is in the viewport
                    if(this.DOM.image){
                        // this.DOM.image.style.opacity = 0;
                        // this.DOM.image.style.display = "none";
                        // this.DOM.image.classList.add('scrollingLettersAnimation5__fade-in');
                    }

                    this.DOM.title.style.opacity = 1;
                    this.DOM.title.classList.add('home__animate');
                    // console.log("this.DOM.title =", this.DOM.title)
                    /*
                       // DOCU - https://stackoverflow.com/questions/3415480/moving-a-div-element-to-bottom-of-parent-as-last-child
                       Each time we hover a project the project link must be in the last position
                       of the parent to be clickable
                    */
                    var containerNode = document.getElementById('home_container');
                    containerNode.parentNode.appendChild(this.DOM.title);

                    // Change background color
                    bodyEl.style.backgroundColor = this.DOM.el.dataset.bgcolor;

                }
            }
            exit(direction) {
                if(direction){
                    // We hide the image only if it is out of the viewport
                    if(this.DOM.image){
                        // this.DOM.image.style.opacity = 0;
                        // this.DOM.image.style.display = "block";
                        // this.DOM.image.classList.add('scrollingLettersAnimation5__fade-in--reverse');
                    }
                    this.DOM.title.style.opacity = 0;
                    this.DOM.title.classList.remove('home__animate');

                    /*
                        // DOCU - https://stackoverflow.com/questions/3415480/moving-a-div-element-to-bottom-of-parent-as-last-child
                        Each time we hover a project the project link must be in the last position 
                        of the parent to be clickable
                    */
                    var containerNode = document.getElementById('home_container');
                    containerNode.parentNode.appendChild(this.DOM.title);
                }
            }
        }

        let observer;
        let current = -1;
        let allentries = [];
        const sections = Array.from(document.querySelectorAll('.home__content__section'));
        // Preload all the images in the page..
        imagesLoaded(document.querySelectorAll('.home__content__img'), () => {
            document.querySelector('.home__demo-5').classList.remove('home__loading');
            if ('IntersectionObserver' in window) {
                document.querySelector('.home__demo-5').classList.add('home__ioapi');

                observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if ( entry.intersectionRatio > 0.5 ) {
                            const newcurrent = sections.indexOf(entry.target);
                            if ( newcurrent === current ) return;
                            const direction = newcurrent > current;
                            if (current >= 0 ) {
                                allentries[current].exit(direction ? 'down' : 'up');
                            }
                            allentries[newcurrent].enter(direction ? 'down' : 'up');
                            current = newcurrent;
                        }
                    });
                }, { threshold: 0.5 });

                sections.forEach(section => allentries.push(new Entry(section)));
            }
        });
    }
}


export default scrollingAnimation5

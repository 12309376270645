<style scoped>
@import "../assets/css/scrolling-animation/home.scss";
@import "../assets/css/projectSectionHome__GridTemplates.scss";

</style>
<template>
  <div
    class="
      home__demo-5 home__loading
    "
  >
    <!-- <svg class="home__hidden">
      <symbol id="home__icon-drop" viewBox="0 0 24 24">
        <title>drop</title>
        <path
          d="M12,21c-3.6,0-6.6-3-6.6-6.6C5.4,11,10.8,4,11.4,3.2C11.6,3.1,11.8,3,12,3s0.4,0.1,0.6,0.3c0.6,0.8,6.1,7.8,6.1,11.2C18.6,18.1,15.6,21,12,21zM12,4.8c-1.8,2.4-5.2,7.4-5.2,9.6c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2C17.2,12.2,13.8,7.3,12,4.8z"
        />
        <path
          d="M12,18.2c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c1.3,0,2.4-1.1,2.4-2.4c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C15.8,16.5,14.1,18.2,12,18.2z"
        />
      </symbol>
    </svg> -->

    <main>
      <div id="home_container"
           class="home__content">
        <!-- Landing page - section -->

        <div class="home__content__section"
             data-bgcolor="#ffffff">
          <div class="">
                <HomeInfiniteGalleryPreview/>
                <HomePreviewImagesGridMobileTablet/>
          </div>
          <router-link to='/'
                   class="home__content__text">
                <h2>Ouvrez les yeux</h2>
          </router-link>
        </div>
        <!-- Projects- sections -->
        <div
          v-for="project in projects"
                 class="home__content__section"
                 :key="project.id"
                 :data-bgcolor="project.dataColorInitial"
        >
            <router-link :to="{name:'project.show', params
                     :{id: project.id,
                     slug: project.slug}}"
                     class="home__content__link"
                     :id="project.id"
            >
                <div :class="`${project.classGridTemplate}`">
                      <ScrollParallax
                          :down="true"
                          :speed="img.dataParallax"
                          :direction="img.dataParallaxDirection"
                          v-for="img in project.imagesProjectHome"
                          :key="img.id"
                          :class="img.class">
                              <img
                                  :data-src="getImgUrlImageProjects(project.slug, img.image)"
                                  :src="getImgUrlImageProjects(project.slug, img.image)"
                                  :class="img.class"
                                  :data-parallax="img.dataParallax">
                      </ScrollParallax>
                </div>
          </router-link>

          <router-link :to="{
                              name:'project.show',
                              params:{
                                id: project.id,
                                slug: project.slug
                              }
                            }"
                       class="home__content__text">
            <h2>
                    {{ project.name.replaceAll("-", " ") }}
            </h2>
          </router-link>
        </div>

        <!-- Last Section: NEXT or Contact Section -->
        <div class="home__content__section"
              data-bgcolor="black">
            <router-link id="home__content__allProjects" to='/projects' class="home__content__text">
                <h2>
                        ALL PROJECTS
                </h2>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>
<script scoped>
import scrollingAnimation5 from "@/assets/js/scrolling-animation/home.js";
import sourceData from "@/data/home_preview_projects.json";
import HomeInfiniteGalleryPreview from '@/components/HomeInfiniteGalleryPreview.vue';
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import HomePreviewImagesGridMobileTablet from "../components/mobile-tablet/HomePreviewImagesGrid.vue";
// import lazyLoadingVanilla from '../assets/js/lazy-loading-images'

export default {
  name: "Home",
  // data() {
  // },
  components: {
          HomeInfiniteGalleryPreview,
          HomePreviewImagesGridMobileTablet,
          ScrollParallax
  },
  // On définit la prop id de type string et obligatoire pour le fonctionnement de la route
  props: {},
  computed: {
    projects() {
      // console.log(sourceData.projects);
      return sourceData.projects;
    },
  },
  methods: {
    getImgUrlImageProjects: function (projectName, imagePath) {
        return require('@/assets/img/projects/' + projectName + '/' + imagePath);
    },
  },
  mounted: function () {
    // Show all the page content during the transition
    document.body.style.opacity = 1
    scrollingAnimation5.animation();
    // lazyLoadingVanilla.load()
  },
};
</script>
<style scoped>
    @import '../assets/css/homeInfiniteGalleryPreview.scss';
</style>
<template>
    <div class="homeInfiniteGalleryPreview__container">
        <div
            class="homeInfiniteGalleryPreview__row"
            v-for="row in projectPreviews"
            :key="row.id">
            <router-link v-for="projectPreview in row.projectPreViewsRow"
                            :key="projectPreview.projectId"
                            :to="{ name:'project.show',
                            params:{id: projectPreview.projectId,
                                    slug: projectPreview.slug}
                            }"
                            :class="`homeInfiniteGalleryPreview__routerlink${projectPreview.projectAreaId}`"
                        >
                <img :id="`homeInfiniteGalleryPreview__picture${projectPreview.projectAreaId}`"
                    :class="`homeInfiniteGalleryPreview__picture
                             homeInfiniteGalleryPreview__picture${projectPreview.projectAreaId}--initial
                             homeInfiniteGalleryPreview__picture${projectPreview.projectAreaId}`"
                    :src="getImgUrlImageProjects(projectPreview.slug, projectPreview.imagesPreview)"
                    :title="projectPreview.slug"
                    v-if="projectPreview.initialPreview == true"/>

                <img :id="`homeInfiniteGalleryPreview__picture${projectPreview.projectAreaId}`"
                    :class="`homeInfiniteGalleryPreview__picture
                             homeInfiniteGalleryPreview__picture${projectPreview.projectAreaId}--first
                             homeInfiniteGalleryPreview__picture${projectPreview.projectAreaId}`"
                    :src="getImgUrlImageProjects(projectPreview.slug, projectPreview.imagesPreview)"
                    :title="projectPreview.slug"/>
            </router-link>
        </div>
    </div>
</template>
<script>
    import sourceData from "@/data/home_infinite_gallery_preview.json";
    // import gsap from 'gsap';
    // import $ from 'jquery'


    export default ({
        name: "homeInfiniteGalleryPreview",
        components: {
        },
        methods: {
            getImgUrlImageProjects: function (projectName, imagePath) {
                return require('@/assets/img/projects/' + projectName + '/' + imagePath);
            },
        },
         computed: {
            projectPreviews() {
            // console.log(sourceData.projectPreviews);
            return sourceData.projectPreviews;
            },
        },
        mounted: function(){
        }
    });
</script>